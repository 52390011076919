/* eslint-disable @typescript-eslint/no-magic-numbers */
import { ChangeDetectorRef, Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { FileType, GuidString, StepModel, StepType } from 'core/models';

@Injectable({
  providedIn: 'root',
})
export class FormValidationService {
  noDuplicateValidator(list: string[], validationName: string, caseSensitive = false): ValidatorFn {
    return ({ value }: AbstractControl) => {
      const option: Intl.CollatorOptions = { sensitivity: caseSensitive ? 'variant' : 'accent' };

      return list
        .filter(x => x !== null && x.trim() !== '')
        .some(x => x.localeCompare(value, undefined, option) === 0)
        ? { [validationName]: true }
        : null;
    };
  }

  waitForSapAcknowledgementStepCountValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const WaitForSapAcknowledgementStepCount = control.value.filter(
        (item: StepModel) => item.type === StepType.WaitForSapAcknowledgement
      );
      return WaitForSapAcknowledgementStepCount.length > 1
        ? { noMultipleSapAcknowledgementStep: true }
        : null;
    };
  }

  fileTypeValidator(fileType: FileType): ValidatorFn {
    return (imgName: AbstractControl) => {
      if (imgName.value !== '') {
        return !imgName.value.toLowerCase().endsWith(fileType) ? { validationName: true } : null;
      }
      return null;
    };
  }

  noWhitespaceValidator(): ValidatorFn {
    return (c: AbstractControl) => (/\s/.test(c.value) ? { noWhitespace: true } : null);
  }

  notOnlyWhitespaceValidator(): ValidatorFn {
    return (c: AbstractControl) => (/^\s*$/.test(c.value) ? { notOnlyWhitespace: true } : null);
  }

  pairExistsValidator(id2: GuidString, guidMatrix: [GuidString, GuidString][]): ValidatorFn {
    return (control: AbstractControl) => {
      const pairExists = guidMatrix?.some(
        ([guid1, guid2]) => guid1 === control.value && guid2 === id2
      );
      return pairExists ? { pairFound: true } : null;
    };
  }

  notSameAsOldValidator(oldValue: string): ValidatorFn {
    return (c: AbstractControl) => {
      const newValue = c.value ? c.value.trim() : '';
      oldValue = oldValue ? oldValue.trim() : '';
      return oldValue === newValue ? { nameExists: true } : null;
    };
  }

  illegalCharacterValidator(): ValidatorFn {
    const nameRegExp = /[!@#$%^&*()+\-=[\]{};':"\\|,.<>/?]/;
    return (c: AbstractControl) =>
      nameRegExp.test(c.value.toString()) ? { illegalChar: true } : null;
  }

  setControlValidationMessage(
    translationKeyPrefix: string,
    msgSetter: (msg: string) => void,
    control: AbstractControl | null,
    ignoreControlState = false,
    cdRef: ChangeDetectorRef | undefined = undefined
  ): void {
    control?.statusChanges.subscribe(() => {
      if ((control.touched || control.dirty || ignoreControlState) && control.errors) {
        const key = translationKeyPrefix.endsWith('.')
          ? translationKeyPrefix
          : `${translationKeyPrefix}.`;
        msgSetter(key + Object.keys(control.errors)[0]);
      } else {
        msgSetter('');
      }

      if (cdRef !== undefined) cdRef.markForCheck();
    });
  }

  greaterThan(toCompare: number): ValidatorFn {
    return ({ value }: AbstractControl) => (value > toCompare ? null : { greaterThan: true });
  }

  characterLimit(charLimit: number): ValidatorFn {
    return ({ value }: AbstractControl) =>
      value.length < charLimit ? null : { characterLimit: true };
  }

  greaterThanDateTime(toCompare: Date): ValidatorFn {
    return ({ value }: AbstractControl) =>
      value > toCompare ? null : { greaterThanDateTime: true };
  }

  setValidatorStatus(isValidatorActive: boolean, validationName: string): ValidatorFn {
    return () => {
      return isValidatorActive ? { [validationName]: false } : null;
    };
  }

  checkMaxWaitingTimeFormat(waitingTimeSeconds: string, waitingTimeMinutes: string): ValidatorFn {
    return () => {
      if (waitingTimeSeconds === '00' && waitingTimeMinutes === '00') {
        return { maxWaitingTimeError: true };
      }

      return null;
    };
  }

  checkMinutesMaskFormat(): ValidatorFn {
    return ({ value }: AbstractControl) => {
      const isValueInvalid = value.length > 2 ? true : false;
      if (Number(value) > 99 || isValueInvalid || value.length < 2) {
        return { incorrectMinutesValue: true };
      }

      return null;
    };
  }

  checkSecondsMaskFormat(): ValidatorFn {
    return ({ value }: AbstractControl) => {
      const isValueInvalid = value.length > 2 ? true : false;
      if (Number(value) > 59 || isValueInvalid || value.length < 2) {
        return { incorrectSecondsValue: true };
      }

      return null;
    };
  }

  jsonValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const error: ValidationErrors = { jsonInvalid: true };
      try {
        JSON.parse(control.value);
      } catch (e) {
        control.setErrors(error);
        return error;
      }
      control.setErrors(null);
      return null;
    };
  }

  validateUrl(control: AbstractControl): ValidationErrors | null {
    try {
      new URL(control.value);
    } catch (e) {
      return { pattern: 'invalid' };
    }
    return null;
  }

  noStartEndWhitespaceValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value as string;
    if (value && value.trim() !== value) {
      return { noStartEndWhitespace: true };
    }
    return null;
  }
}
