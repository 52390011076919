import { IconOptions, RectangleOptions } from 'modules/maps/helpers';
import { Graphics, Sprite, Texture } from 'pixi.js';
import { ItemLabelStyle } from '../map-layer.constant';
import { MapLayerItemDimensions } from '../map-layer.models';
import { MapLabelGraphic } from '../shared';

export class LayerGraphicHelper {
  // #region Create
  static createIcon(texture: Texture, options: IconOptions): Sprite {
    const icon = Sprite.from(texture, options);

    icon.scale.set(1 / options.scale);
    icon.pivot.set((icon.width * options.scale) / 2); // Icons centre when scaled, in middle X axis

    return icon;
  }

  static createLabel(text: string): MapLabelGraphic {
    return new MapLabelGraphic({
      label: text,
      scale: ItemLabelStyle.Scale,
      alpha: ItemLabelStyle.Opacity,
      radius: ItemLabelStyle.Radius,
      shadow: false,
    });
  }

  static createBaseFromDimensions(
    dimensions: MapLayerItemDimensions,
    options: RectangleOptions
  ): Graphics {
    return new Graphics()
      .beginFill(options.color, options.alpha)
      .lineStyle(options.borderSize, options.borderColor)
      .drawRoundedRect(
        -dimensions.origin, // Distance from rear
        -dimensions.width / 2,
        dimensions.length,
        dimensions.width,
        options.corner ?? 0
      )
      .endFill();
  }

  // #endregion

  // #region Positioning

  static positionToItemCenter(icon: Sprite | Graphics, dimensions: MapLayerItemDimensions): void {
    this.positionToXOffset(icon, dimensions, dimensions.length / 2);
  }

  // Sets the position to an offset from the center of the item
  static positionOffsetFromItemCenter(
    icon: Sprite | Graphics,
    dimensions: MapLayerItemDimensions,
    xOffset: number,
    yOffset: number
  ): void {
    const center = dimensions.length / 2;

    icon.position.copyFrom({
      x: center - dimensions.origin - xOffset,
      y: yOffset,
    });
  }

  static positionPercentageFromItemCenter(
    icon: Sprite | Graphics,
    dimensions: MapLayerItemDimensions,
    xPercentage: number,
    yPercentage: number
  ): void {
    const center = dimensions.length / 2;
    const xOffset = center * xPercentage;
    const yOffset = (dimensions.width / 2) * yPercentage;

    icon.position.copyFrom({
      x: center - dimensions.origin + xOffset,
      y: yOffset,
    });
  }

  // Sets the position to an offset of the item body, from origin
  static positionToXOffset(
    icon: Sprite | Graphics,
    dimensions: MapLayerItemDimensions,
    offset: number
  ): void {
    this.positionToOffset(icon, dimensions, offset, 0);
  }

  static positionToOffset(
    icon: Sprite | Graphics,
    dimensions: MapLayerItemDimensions,
    xOffset: number,
    yOffset: number
  ): void {
    icon.position.copyFrom({
      x: xOffset - dimensions.origin,
      y: yOffset,
    });
  }

  static positionToOrigin(icon: Sprite | Graphics): void {
    icon.position.copyFrom({
      x: 0,
      y: 0,
    });
  }

  // #endregion
}
