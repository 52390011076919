<ds-box class="mb-4x" data-cy="card">
  <ds-box-header>
    <app-labeled-input
      label="{{
        'settings.featureToggles.jobAssignmentSection.jobAssignment' | translate | uppercase
      }}"></app-labeled-input>
  </ds-box-header>
  <ds-box-content>
    <app-parky-chargy-jm-toggle
      data-cy="parkingAndChargingToggle"
      translationKey="settings.functions.parkingAndCharging"
      description="settings.featureToggles.jobAssignmentSection.parkingChargingDescription"
      tooltipText="{{
        ('settings.roles.permissions.Tooltip_Pretext' | translate) +
          ('settings.roles.permissions.ToggleParkyChargy' | translate) +
          ('settings.roles.permissions.Tooltip_Posttext' | translate)
      }}"
      [toggle]="viewModel.parkingAndCharging"
      [loadedVehiclesToggle]="viewModel.parkingAndChargingLoadedVehicles"
      [isLoaded]="jobManagerSettingsLoaded"
      [requiredPermission]="'ToggleParkyChargy'"
      (saveToggle)="onChangeParkingAndCharging($event)"></app-parky-chargy-jm-toggle>

    <app-toggle
      data-cy="waitOnPoiToggle"
      translationKey="settings.functions.waitOnPoi"
      description="settings.featureToggles.jobAssignmentSection.waitOnPoiDescription"
      tooltipText="{{
        ('settings.roles.permissions.Tooltip_Pretext' | translate) +
          ('settings.roles.permissions.ToggleWaitOnPoi' | translate) +
          ('settings.roles.permissions.Tooltip_Posttext' | translate)
      }}"
      [toggle]="viewModel.waitOnPoi"
      [disabled]="!viewModel.parkingAndCharging.isToggledOn"
      [isLoaded]="jobManagerSettingsLoaded"
      [requiredPermission]="'ToggleWaitOnPoi'"
      (saveToggle)="onChangeWaitOnPoi($event)"></app-toggle>
  </ds-box-content>
  <ds-box-content>
    <h5>
      {{ 'settings.featureToggles.jobAssignmentSection.loadingTypeTable.caption' | translate }}
    </h5>
    <app-load-type-table
      [workAreaId]="workAreaId"
      [loadTypeConfigurations]="viewModel.loadTypeSettings.configurations">
    </app-load-type-table>
  </ds-box-content>
</ds-box>

<ds-box class="mb-4x" data-cy="card">
  <ds-box-header>
    <app-labeled-input
      label="{{
        'settings.featureToggles.trafficManagementSection.trafficManagement' | translate | uppercase
      }}"></app-labeled-input>
  </ds-box-header>
  <ds-box-content>
    <app-toggle
      data-cy="trafficManagementToggle"
      translationKey="settings.functions.trafficManagement"
      description="settings.featureToggles.trafficManagementSection.trafficDescription"
      tooltipText="{{
        ('settings.roles.permissions.Tooltip_Pretext' | translate) +
          ('settings.roles.permissions.ToggleTrafficManagement' | translate) +
          ('settings.roles.permissions.Tooltip_Posttext' | translate)
      }}"
      [toggle]="viewModel.trafficManagementSettings"
      [isLoaded]="trafficManagerSettingsLoaded"
      [requiredPermission]="'ToggleTrafficManagement'"
      (saveToggle)="onChangeTrafficManagementSettings($event)"></app-toggle>

    <div class="settingsTableDiv">
      <ds-accordion class="configuration-accordion">
        <ds-accordion-item
          data-cy="configurationVehicleTypeParameters"
          contentSpacing="sm"
          variant="slim">
          {{ 'settings.featureToggles.trafficManagementSection.vehicleTypeParameters' | translate }}
          <div class="positionDescription" content>
            <table class="vehicleTypeSettingsTable">
              <thead>
                <tr>
                  <th class="col-8">
                    <div class="columnSeparator">
                      {{ 'settings.featureToggles.trafficManagementSection.property' | translate }}
                    </div>
                  </th>
                  <th class="col-1">
                    {{ 'settings.featureToggles.trafficManagementSection.unitLoad' | translate }}
                  </th>
                  <th class="col-1">
                    {{ 'settings.featureToggles.trafficManagementSection.tuggerTrain' | translate }}
                  </th>
                  <th class="col-1">
                    {{ 'settings.featureToggles.trafficManagementSection.forklift' | translate }}
                  </th>
                  <th class="col-1">
                    {{ 'settings.featureToggles.trafficManagementSection.uagv' | translate }}
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>
                    <span class="align-sub" data-cy="considerProperty">{{
                      'settings.featureToggles.trafficManagementSection.consider' | translate
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="considerUnitLoadValue">{{
                      viewModel.trafficManagementSettings.considerUnitLoad
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="considerTuggerTrainValue">{{
                      viewModel.trafficManagementSettings.considerTuggerTrain
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="considerForkliftValue">{{
                      viewModel.trafficManagementSettings.considerForklift
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="considerUagvValue">{{
                      viewModel.trafficManagementSettings.considerUagv
                    }}</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="align-sub" data-cy="considerIdleProperty">{{
                      'settings.featureToggles.trafficManagementSection.considerIdle' | translate
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="considerIdleUnitLoadValue">{{
                      viewModel.trafficManagementSettings.considerIdleUnitLoad
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="considerIdleTuggerTrainValue">{{
                      viewModel.trafficManagementSettings.considerIdleTuggerTrain
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="considerIdleForkliftValue">{{
                      viewModel.trafficManagementSettings.considerIdleForklift
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="considerIdleUagvValue">{{
                      viewModel.trafficManagementSettings.considerIdleUagv
                    }}</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="align-sub" data-cy="considerInManualModeProperty">{{
                      'settings.featureToggles.trafficManagementSection.considerInManualMode'
                        | translate
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="considerUnitLoadInManualModeValue">{{
                      viewModel.trafficManagementSettings.considerUnitLoadInManualMode
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="considerTuggerTrainInManualModeValue">{{
                      viewModel.trafficManagementSettings.considerTuggerTrainInManualMode
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="considerForkliftInManualModeValue">{{
                      viewModel.trafficManagementSettings.considerForkliftInManualMode
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="considerUagvInManualModeValue">{{
                      viewModel.trafficManagementSettings.considerUagvInManualMode
                    }}</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="align-sub" data-cy="considerManualModeInZoneManagementProperty">{{
                      'settings.featureToggles.trafficManagementSection.considerManualModeInZoneManagement'
                        | translate
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="considerManualUnitLoadInZoneManagementValue">{{
                      viewModel.trafficManagementSettings.considerManualUnitLoadInZoneManagement
                    }}</span>
                  </td>
                  <td>
                    <span
                      class="align-sub"
                      data-cy="considerManualTuggerTrainInZoneManagementValue"
                      >{{
                        viewModel.trafficManagementSettings
                          .considerManualTuggerTrainInZoneManagement
                      }}</span
                    >
                  </td>
                  <td>
                    <span class="align-sub" data-cy="considerManualForkliftInZoneManagementValue">{{
                      viewModel.trafficManagementSettings.considerManualForkliftInZoneManagement
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="considerManualUagvInZoneManagementValue">{{
                      viewModel.trafficManagementSettings.considerManualUagvInZoneManagement
                    }}</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="align-sub" data-cy="considerInErrorProperty">{{
                      'settings.featureToggles.trafficManagementSection.considerInError' | translate
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="considerUnitLoadInErrorValue">{{
                      viewModel.trafficManagementSettings.considerUnitLoadInError
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="considerTuggerTrainInErrorValue">{{
                      viewModel.trafficManagementSettings.considerTuggerTrainInError
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="considerForkliftInErrorValue">{{
                      viewModel.trafficManagementSettings.considerForkliftInError
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="considerUagvInErrorValue">{{
                      viewModel.trafficManagementSettings.considerUagvInError
                    }}</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="align-sub" data-cy="ignoreCommonVehicleTypeCollisionsProperty">{{
                      'settings.featureToggles.trafficManagementSection.ignoreCommonVehicleTypeCollisions'
                        | translate
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="ignoreCommonUnitLoadCollisionValue">{{
                      viewModel.trafficManagementSettings.ignoreCommonUnitLoadCollision
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="ignoreCommonTuggerTrainCollisionValue">{{
                      viewModel.trafficManagementSettings.ignoreCommonTuggerTrainCollision
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="ignoreCommonForkliftCollisionValue">{{
                      viewModel.trafficManagementSettings.ignoreCommonForkliftCollision
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="ignoreCommonUagvCollisionValue">{{
                      viewModel.trafficManagementSettings.ignoreCommonUagvCollision
                    }}</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="align-sub" data-cy="lookAheadAreaMinimumLengthMetersProperty">{{
                      'settings.featureToggles.trafficManagementSection.lookAheadAreaMinimumLengthMeters'
                        | translate
                    }}</span>
                  </td>
                  <td>
                    <span
                      class="align-sub"
                      data-cy="unitLoadLookAheadAreaMinimumLengthMetersValue"
                      >{{
                        viewModel.trafficManagementSettings.unitLoadLookAheadAreaMinimumLengthMeters
                      }}</span
                    >
                  </td>
                  <td>
                    <span
                      class="align-sub"
                      data-cy="tuggerTrainLookAheadAreaMinimumLengthMetersValue"
                      >{{
                        viewModel.trafficManagementSettings
                          .tuggerTrainLookAheadAreaMinimumLengthMeters
                      }}</span
                    >
                  </td>
                  <td>
                    <span
                      class="align-sub"
                      data-cy="forkliftLookAheadAreaMinimumLengthMetersValue"
                      >{{
                        viewModel.trafficManagementSettings.forkliftLookAheadAreaMinimumLengthMeters
                      }}</span
                    >
                  </td>
                  <td>
                    <span class="align-sub" data-cy="uagvLookAheadAreaMinimumLengthMetersValue">{{
                      viewModel.trafficManagementSettings.uagvLookAheadAreaMinimumLengthMeters
                    }}</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="align-sub" data-cy="lookAheadAreaSpeedMultiplierProperty">{{
                      'settings.featureToggles.trafficManagementSection.lookAheadAreaSpeedMultiplier'
                        | translate
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="unitLoadLookAheadAreaSpeedMultiplierValue">{{
                      viewModel.trafficManagementSettings.unitLoadLookAheadAreaSpeedMultiplier
                    }}</span>
                  </td>
                  <td>
                    <span
                      class="align-sub"
                      data-cy="tuggerTrainLookAheadAreaSpeedMultiplierValue"
                      >{{
                        viewModel.trafficManagementSettings.tuggerTrainLookAheadAreaSpeedMultiplier
                      }}</span
                    >
                  </td>
                  <td>
                    <span class="align-sub" data-cy="forkliftLookAheadAreaSpeedMultiplierValue">{{
                      viewModel.trafficManagementSettings.forkliftLookAheadAreaSpeedMultiplier
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="uagvLookAheadAreaSpeedMultiplierValue">{{
                      viewModel.trafficManagementSettings.uagvLookAheadAreaSpeedMultiplier
                    }}</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="align-sub" data-cy="lookAheadAreaWidthMetersProperty">{{
                      'settings.featureToggles.trafficManagementSection.lookAheadAreaWidthMeters'
                        | translate
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="unitLoadLookAheadAreaWidthMetersValue">{{
                      viewModel.trafficManagementSettings.unitLoadLookAheadAreaWidthMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="tuggerTrainLookAheadAreaWidthMetersValue">{{
                      viewModel.trafficManagementSettings.tuggerTrainLookAheadAreaWidthMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="forkliftLookAheadAreaWidthMetersValue">{{
                      viewModel.trafficManagementSettings.forkliftLookAheadAreaWidthMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="uagvLookAheadAreaWidthMetersValue">{{
                      viewModel.trafficManagementSettings.uagvLookAheadAreaWidthMeters
                    }}</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="align-sub" data-cy="loadedExtraWidthMetersProperty">{{
                      'settings.featureToggles.trafficManagementSection.loadedExtraWidthMeters'
                        | translate
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="unitLoadLoadedExtraWidthMetersValue">{{
                      viewModel.trafficManagementSettings.unitLoadLoadedExtraWidthMeters
                    }}</span>
                  </td>
                  <td>
                    <span
                      class="align-sub"
                      data-cy="tuggerTrainLookAheadAreaLoadedExtraWidthMetersValue"
                      >{{
                        viewModel.trafficManagementSettings
                          .tuggerTrainLookAheadAreaLoadedExtraWidthMeters
                      }}</span
                    >
                  </td>
                  <td>
                    <span class="align-sub" data-cy="forkliftLoadedExtraWidthMetersValue">{{
                      viewModel.trafficManagementSettings.forkliftLoadedExtraWidthMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="uagvLoadedExtraWidthMetersValue">{{
                      viewModel.trafficManagementSettings.uagvLoadedExtraWidthMeters
                    }}</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="align-sub" data-cy="rearLengthMetersProperty">{{
                      'settings.featureToggles.trafficManagementSection.rearLengthMeters'
                        | translate
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="unitLoadRearLengthMetersValue">{{
                      viewModel.trafficManagementSettings.unitLoadRearLengthMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="tuggerTrainRearLengthMetersValue">{{
                      viewModel.trafficManagementSettings.tuggerTrainRearLengthMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="forkliftRearLengthMetersValue">{{
                      viewModel.trafficManagementSettings.forkliftRearLengthMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="uagvRearLengthMetersValue">{{
                      viewModel.trafficManagementSettings.uagvRearLengthMeters
                    }}</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="align-sub" data-cy="frontLengthMetersProperty">{{
                      'settings.featureToggles.trafficManagementSection.frontLengthMeters'
                        | translate
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="unitLoadFrontLengthMetersValue">{{
                      viewModel.trafficManagementSettings.unitLoadFrontLengthMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="tuggerTrainFrontLengthMetersValue">{{
                      viewModel.trafficManagementSettings.tuggerTrainFrontLengthMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="forkliftFrontLengthMetersValue">{{
                      viewModel.trafficManagementSettings.forkliftFrontLengthMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="uagvFrontLengthMetersValue">{{
                      viewModel.trafficManagementSettings.uagvFrontLengthMeters
                    }}</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="align-sub" data-cy="rearWidthMetersProperty">{{
                      'settings.featureToggles.trafficManagementSection.rearWidthMeters' | translate
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="unitLoadRearWidthMetersValue">{{
                      viewModel.trafficManagementSettings.unitLoadRearWidthMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="tuggerTrainRearWidthMetersValue">{{
                      viewModel.trafficManagementSettings.tuggerTrainRearWidthMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="forkliftRearWidthMetersValue">{{
                      viewModel.trafficManagementSettings.forkliftRearWidthMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="uagvRearWidthMetersValue">{{
                      viewModel.trafficManagementSettings.uagvRearWidthMeters
                    }}</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="align-sub" data-cy="stoppingAreaWidthMetersProperty">{{
                      'settings.featureToggles.trafficManagementSection.stoppingAreaWidthMeters'
                        | translate
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="unitLoadStoppingAreaWidthMetersValue">{{
                      viewModel.trafficManagementSettings.unitLoadStoppingAreaWidthMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="tuggerTrainStoppingAreaWidthMetersValue">{{
                      viewModel.trafficManagementSettings.tuggerTrainStoppingAreaWidthMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="forkliftStoppingAreaWidthMetersValue">{{
                      viewModel.trafficManagementSettings.forkliftStoppingAreaWidthMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="uagvStoppingAreaWidthMetersValue">{{
                      viewModel.trafficManagementSettings.uagvStoppingAreaWidthMeters
                    }}</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="align-sub" data-cy="deadlockAreaLengthMetersProperty">{{
                      'settings.featureToggles.trafficManagementSection.deadlockAreaLengthMeters'
                        | translate
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="unitLoadDeadlockAreaLengthMetersValue">{{
                      viewModel.trafficManagementSettings.unitLoadDeadlockAreaLengthMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="tuggerTrainDeadlockAreaLengthMetersValue">{{
                      viewModel.trafficManagementSettings.tuggerTrainDeadlockAreaLengthMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="forkliftDeadlockAreaLengthMetersValue">{{
                      viewModel.trafficManagementSettings.forkliftDeadlockAreaLengthMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="uagvDeadlockAreaLengthMetersValue">{{
                      viewModel.trafficManagementSettings.uagvDeadlockAreaLengthMeters
                    }}</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="align-sub" data-cy="deadlockAreaWidthMetersProperty">{{
                      'settings.featureToggles.trafficManagementSection.deadlockAreaWidthMeters'
                        | translate
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="unitLoadDeadlockAreaWidthMetersValue">{{
                      viewModel.trafficManagementSettings.unitLoadDeadlockAreaWidthMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="tuggerTrainDeadlockAreaWidthMetersValue">{{
                      viewModel.trafficManagementSettings.tuggerTrainDeadlockAreaWidthMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="forkliftDeadlockAreaWidthMetersValue">{{
                      viewModel.trafficManagementSettings.forkliftDeadlockAreaWidthMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="uagvDeadlockAreaWidthMetersValue">{{
                      viewModel.trafficManagementSettings.uagvDeadlockAreaWidthMeters
                    }}</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="align-sub" data-cy="deadlockAreaLoadedWidthMetersProperty">{{
                      'settings.featureToggles.trafficManagementSection.deadlockAreaLoadedWidthMeters'
                        | translate
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="unitLoadDeadlockAreaLoadedWidthMetersValue">{{
                      viewModel.trafficManagementSettings.unitLoadDeadlockAreaLoadedWidthMeters
                    }}</span>
                  </td>
                  <td>
                    <span
                      class="align-sub"
                      data-cy="tuggerTrainDeadlockAreaLoadedWidthMetersValue"
                      >{{
                        viewModel.trafficManagementSettings.tuggerTrainDeadlockAreaLoadedWidthMeters
                      }}</span
                    >
                  </td>
                  <td>
                    <span class="align-sub" data-cy="forkliftDeadlockAreaLoadedWidthMetersValue">{{
                      viewModel.trafficManagementSettings.forkliftDeadlockAreaLoadedWidthMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="uagvDeadlockAreaLoadedWidthMetersValue">{{
                      viewModel.trafficManagementSettings.uagvDeadlockAreaLoadedWidthMeters
                    }}</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="align-sub" data-cy="footprintBufferMetersProperty">{{
                      'settings.featureToggles.trafficManagementSection.footprintBufferMeters'
                        | translate
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="unitLoadFootprintBufferMetersValue">{{
                      viewModel.trafficManagementSettings.unitLoadFootprintBufferMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="tuggerTrainFootprintBufferMetersValue">{{
                      viewModel.trafficManagementSettings.tuggerTrainFootprintBufferMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="forkliftFootprintBufferMetersValue">{{
                      viewModel.trafficManagementSettings.forkliftFootprintBufferMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="uagvFootprintBufferMetersValue">{{
                      viewModel.trafficManagementSettings.uagvFootprintBufferMeters
                    }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </ds-accordion-item>
      </ds-accordion>

      <ds-accordion class="configuration-accordion">
        <ds-accordion-item
          data-cy="configurationTechnicalParameters"
          contentSpacing="sm"
          variant="slim">
          {{ 'settings.featureToggles.trafficManagementSection.technicalParameters' | translate }}
          <div class="positionDescription" content>
            <table class="trafficManagementSettingsTable">
              <thead>
                <tr>
                  <th>
                    <div class="columnSeparator">
                      {{ 'settings.featureToggles.trafficManagementSection.property' | translate }}
                    </div>
                  </th>
                  <th>
                    {{ 'settings.featureToggles.trafficManagementSection.value' | translate }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <span class="align-sub" data-cy="minimumStoppingAreaLengthMetersProperty">{{
                      'settings.featureToggles.trafficManagementSection.minimumStoppingAreaLengthMeters'
                        | translate
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="minimumStoppingAreaLengthMetersValue">{{
                      viewModel.trafficManagementSettings.minimumStoppingAreaLengthMeters
                    }}</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span
                      class="align-sub"
                      data-cy="backwardMovementMinNegativeVelocityMetersPerSecondProperty"
                      >{{
                        'settings.featureToggles.trafficManagementSection.backwardMovementMinNegativeVelocityMetersPerSecond'
                          | translate
                      }}</span
                    >
                  </td>
                  <td>
                    <span
                      class="align-sub"
                      data-cy="backwardMovementMinNegativeVelocityMetersPerSecondValue"
                      >{{
                        viewModel.trafficManagementSettings
                          .backwardMovementMinNegativeVelocityMetersPerSecond
                      }}</span
                    >
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="align-sub" data-cy="vehicleTurningDetectionAngleRadProperty">{{
                      'settings.featureToggles.trafficManagementSection.vehicleTurningDetectionAngleRad'
                        | translate
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="vehicleTurningDetectionAngleRadValue">{{
                      viewModel.trafficManagementSettings.vehicleTurningDetectionAngleRad
                    }}</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="align-sub" data-cy="staleCollisionDistanceMetersProperty">{{
                      'settings.featureToggles.trafficManagementSection.staleCollisionDistanceMeters'
                        | translate
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="staleCollisionDistanceMetersValue">{{
                      viewModel.trafficManagementSettings.staleCollisionDistanceMeters
                    }}</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="align-sub" data-cy="convoyMaxConflictAngleRadProperty">{{
                      'settings.featureToggles.trafficManagementSection.convoyMaxConflictAngleRad'
                        | translate
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="convoyMaxConflictAngleRadValue">{{
                      viewModel.trafficManagementSettings.convoyMaxConflictAngleRad
                    }}</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span
                      class="align-sub"
                      data-cy="convoyMaxDistanceToPrioritizedVehicleMetersProperty"
                      >{{
                        'settings.featureToggles.trafficManagementSection.convoyMaxDistanceToPrioritizedVehicleMeters'
                          | translate
                      }}</span
                    >
                  </td>
                  <td>
                    <span
                      class="align-sub"
                      data-cy="convoyMaxDistanceToPrioritizedVehicleMetersValue"
                      >{{
                        viewModel.trafficManagementSettings
                          .convoyMaxDistanceToPrioritizedVehicleMeters
                      }}</span
                    >
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="align-sub" data-cy="preferredDirectionMaxAngleRadProperty">{{
                      'settings.featureToggles.trafficManagementSection.preferredDirectionMaxAngleRad'
                        | translate
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="preferredDirectionMaxAngleRadValue">{{
                      viewModel.trafficManagementSettings.preferredDirectionMaxAngleRad
                    }}</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="align-sub" data-cy="vehicleStateConsiderationTtlSecondsProperty">{{
                      'settings.featureToggles.trafficManagementSection.vehicleStateConsiderationTtlSeconds'
                        | translate
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="vehicleStateConsiderationTtlSecondsValue">{{
                      viewModel.trafficManagementSettings.vehicleStateConsiderationTtlSeconds
                    }}</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span
                      class="align-sub"
                      data-cy="staleDeadlockCollisionDistanceMetersProperty"
                      >{{
                        'settings.featureToggles.trafficManagementSection.staleDeadlockCollisionDistanceMeters'
                          | translate
                      }}</span
                    >
                  </td>
                  <td>
                    <span class="align-sub" data-cy="staleDeadlockCollisionDistanceMetersValue">{{
                      viewModel.trafficManagementSettings.staleDeadlockCollisionDistanceMeters
                    }}</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="align-sub" data-cy="maxDistanceToIntersectionZoneMetersProperty">{{
                      'settings.featureToggles.trafficManagementSection.maximumDistanceToIntersectionZoneMeters'
                        | translate
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="maxDistanceToIntersectionZoneMetersValue">{{
                      viewModel.trafficManagementSettings.maximumDistanceToIntersectionZoneMeters
                    }}</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span
                      class="align-sub"
                      data-cy="antiBlockingHeadsOnMaxOrientationRadProperty"
                      >{{
                        'settings.featureToggles.trafficManagementSection.antiBlockingHeadsOnMaxOrientationRad'
                          | translate
                      }}</span
                    >
                  </td>
                  <td>
                    <span class="align-sub" data-cy="antiBlockingHeadsOnMaxOrientationRadValue">{{
                      viewModel.trafficManagementSettings.antiBlockingHeadsOnMaxOrientationRad
                    }}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      class="align-sub"
                      data-cy="antiBlockingHeadsOnMaxDistanceMetersProperty"
                      >{{
                        'settings.featureToggles.trafficManagementSection.antiBlockingHeadsOnMaxDistanceMeters'
                          | translate
                      }}</span
                    >
                  </td>
                  <td>
                    <span class="align-sub" data-cy="antiBlockingHeadsOnMaxDistanceMetersValue">{{
                      viewModel.trafficManagementSettings.antiBlockingHeadsOnMaxDistanceMeters
                    }}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      class="align-sub"
                      data-cy="antiBlockingMovingDetectionMinDistanceMetersProperty"
                      >{{
                        'settings.featureToggles.trafficManagementSection.antiBlockingMovingDetectionMinDistanceMeters'
                          | translate
                      }}</span
                    >
                  </td>
                  <td>
                    <span
                      class="align-sub"
                      data-cy="antiBlockingMovingDetectionMinDistanceMetersValue"
                      >{{
                        viewModel.trafficManagementSettings
                          .antiBlockingMovingDetectionMinDistanceMeters
                      }}</span
                    >
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      class="align-sub"
                      data-cy="antiBlockingMovingDetectionMinOrientationRadProperty"
                      >{{
                        'settings.featureToggles.trafficManagementSection.antiBlockingMovingDetectionMinOrientationRad'
                          | translate
                      }}</span
                    >
                  </td>
                  <td>
                    <span
                      class="align-sub"
                      data-cy="antiBlockingMovingDetectionMinOrientationRadValue"
                      >{{
                        viewModel.trafficManagementSettings
                          .antiBlockingMovingDetectionMinOrientationRad
                      }}</span
                    >
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      class="align-sub"
                      data-cy="antiBlockingMaxStationaryVehicleSecondsProperty"
                      >{{
                        'settings.featureToggles.trafficManagementSection.antiBlockingMaxStationaryVehicleSeconds'
                          | translate
                      }}</span
                    >
                  </td>
                  <td>
                    <span
                      class="align-sub"
                      data-cy="antiBlockingMaxStationaryVehicleSecondsValue"
                      >{{
                        viewModel.trafficManagementSettings.antiBlockingMaxStationaryVehicleSeconds
                      }}</span
                    >
                  </td>
                </tr>

                <tr>
                  <td>
                    <span
                      class="align-sub"
                      data-cy="antiBlockingStaleDistanceToZoneRuleSecondsProperty"
                      >{{
                        'settings.featureToggles.trafficManagementSection.antiBlockingStaleDistanceToZoneRuleSeconds'
                          | translate
                      }}</span
                    >
                  </td>
                  <td>
                    <span
                      class="align-sub"
                      data-cy="antiBlockingStaleDistanceToZoneRuleSecondsValue"
                      >{{
                        viewModel.trafficManagementSettings
                          .antiBlockingStaleDistanceToZoneRuleSeconds
                      }}</span
                    >
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="align-sub" data-cy="isAntiBlockingHeadsOnEnabledProperty">{{
                      'settings.featureToggles.trafficManagementSection.isAntiBlockingHeadsOnEnabled'
                        | translate
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="isAntiBlockingHeadsOnEnabledValue">{{
                      viewModel.trafficManagementSettings.isAntiBlockingHeadsOnEnabled
                    }}</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span
                      class="align-sub"
                      data-cy="isAntiBlockingWaitingForEventEnabledProperty"
                      >{{
                        'settings.featureToggles.trafficManagementSection.isAntiBlockingWaitingForEventEnabled'
                          | translate
                      }}</span
                    >
                  </td>
                  <td>
                    <span class="align-sub" data-cy="isAntiBlockingWaitingForEventEnabledValue">{{
                      viewModel.trafficManagementSettings.isAntiBlockingWaitingForEventEnabled
                    }}</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="align-sub" data-cy="isAntiBlockingInsideZoneEnabledProperty">{{
                      'settings.featureToggles.trafficManagementSection.isAntiBlockingInsideZoneEnabled'
                        | translate
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="isAntiBlockingInsideZoneEnabledValue">{{
                      viewModel.trafficManagementSettings.isAntiBlockingInsideZoneEnabled
                    }}</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="align-sub" data-cy="isAntiBlockingDisconnectEnabledProperty">{{
                      'settings.featureToggles.trafficManagementSection.isAntiBlockingDisconnectEnabled'
                        | translate
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="isAntiBlockingDisconnectEnabledValue">{{
                      viewModel.trafficManagementSettings.isAntiBlockingDisconnectEnabled
                    }}</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="align-sub" data-cy="isAntiBlockingErrorStateEnabledProperty">{{
                      'settings.featureToggles.trafficManagementSection.isAntiBlockingErrorStateEnabled'
                        | translate
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="isAntiBlockingErrorStateEnabledValue">{{
                      viewModel.trafficManagementSettings.isAntiBlockingErrorStateEnabled
                    }}</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="align-sub" data-cy="isAntiBlockingStationaryEnabledProperty">{{
                      'settings.featureToggles.trafficManagementSection.isAntiBlockingStationaryEnabled'
                        | translate
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="isAntiBlockingStationaryEnabledValue">{{
                      viewModel.trafficManagementSettings.isAntiBlockingStationaryEnabled
                    }}</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span
                      class="align-sub"
                      data-cy="isAntiBlockingStaleDistanceToZoneRuleEnabledProperty"
                      >{{
                        'settings.featureToggles.trafficManagementSection.isAntiBlockingStaleDistanceToZoneRuleEnabled'
                          | translate
                      }}</span
                    >
                  </td>
                  <td>
                    <span
                      class="align-sub"
                      data-cy="isAntiBlockingStaleDistanceToZoneRuleEnabledValue"
                      >{{
                        viewModel.trafficManagementSettings
                          .isAntiBlockingStaleDistanceToZoneRuleEnabled
                      }}</span
                    >
                  </td>
                </tr>

                <tr>
                  <td>
                    <span
                      class="align-sub"
                      data-cy="isDirectedZoneRuleWithLookaheadAreaEnabledProperty"
                      >{{
                        'settings.featureToggles.trafficManagementSection.isDirectedZoneRuleWithLookaheadAreaEnabled'
                          | translate
                      }}</span
                    >
                  </td>
                  <td>
                    <span
                      class="align-sub"
                      data-cy="isDirectedZoneRuleWithLookaheadAreaEnabledValue"
                      >{{
                        viewModel.trafficManagementSettings
                          .isDirectedZoneRuleWithLookaheadAreaEnabled
                      }}</span
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </ds-accordion-item>
      </ds-accordion>
    </div>
  </ds-box-content>
</ds-box>

<ds-box class="mb-4x" data-cy="card">
  <ds-box-content>
    <app-toggle
      data-cy="deviceSettingToggle"
      translationKey="settings.functions.deviceSettings"
      description="settings.featureToggles.amaSection.deviceSettingsDescription"
      tooltipText="{{
        ('settings.roles.permissions.Tooltip_Pretext' | translate) +
          ('settings.roles.permissions.ToggleAMAFunctionality' | translate) +
          ('settings.roles.permissions.Tooltip_Posttext' | translate)
      }}"
      [toggle]="viewModel.deviceSettings"
      [isLoaded]="jobManagerSettingsLoaded"
      [requiredPermission]="'ToggleAMAFunctionality'"
      (saveToggle)="onChangeAmaSettings($event)"></app-toggle>
  </ds-box-content>
</ds-box>

<ds-box class="mb-4x" data-cy="card">
  <ds-box-content>
    <app-toggle
      data-cy="enableWaitingQueueToggle"
      translationKey="settings.functions.waitingQueue"
      description="settings.featureToggles.amaSection.enableWaitingQueueDescription"
      tooltipText="{{
        ('settings.roles.permissions.Tooltip_Pretext' | translate) +
          ('settings.roles.permissions.ToggleWaitingQueueMode' | translate) +
          ('settings.roles.permissions.Tooltip_Posttext' | translate)
      }}"
      [toggle]="viewModel.waitingQueueSettings"
      [isLoaded]="jobManagerSettingsLoaded"
      [requiredPermission]="'ToggleWaitingQueueMode'"
      (saveToggle)="onChangeWaitingQueueSettings($event)"></app-toggle>
  </ds-box-content>
</ds-box>

<ds-box class="mb-4x" data-cy="card">
  <ds-box-content>
    <app-toggle
      data-cy="enableGraphManagerFeature"
      translationKey="settings.functions.graphManager"
      description="settings.functions.graphManager.Description"
      tooltipText="{{
        ('settings.roles.permissions.Tooltip_Pretext' | translate) +
          ('settings.roles.permissions.ToggleWaitingQueueMode' | translate) +
          ('settings.roles.permissions.Tooltip_Posttext' | translate)
      }}"
      [toggle]="viewModel.graphManagerSettings"
      [isLoaded]="graphManagerSettingsLoaded"
      [requiredPermission]="'ToggleWaitingQueueMode'"
      (saveToggle)="onChangeGraphManagerSettings($event)"></app-toggle>

    <app-toggle
      data-cy="missionAssignmentOptimizationToggle"
      translationKey="settings.functions.graphManager.missionAssignment"
      description="settings.functions.graphManager.missionAssignment.missionAssignmentOptimizationDescription"
      tooltipText="{{
        ('settings.roles.permissions.Tooltip_Pretext' | translate) +
          ('settings.roles.permissions.ToggleMissionAssignment' | translate) +
          ('settings.roles.permissions.Tooltip_Posttext' | translate)
      }}"
      [toggle]="viewModel.graphManagerMissionAssignmentOptimization"
      [isLoaded]="graphManagerSettingsLoaded"
      [requiredPermission]="'ToggleMissionAssignment'"
      (saveToggle)="onChangeMissionAssignmentOptimization($event)"></app-toggle>

    <app-parky-chargy-toggle
      [isLoaded]="graphManagerParkingAndChargingSettingsLoaded"
      [parkingAndChargingSettings]="viewModel.graphManagerParkingAndChargingSettings"
      (updateParkyChargyVehicleToggle)="
        onChangeGraphManagerParkingAndChargingSettings($event)
      "></app-parky-chargy-toggle>
    <ds-accordion class="configuration-accordion">
      <ds-accordion-item
        data-cy="parkingAndChargingSettingsAccordion"
        contentSpacing="sm"
        variant="slim">
        {{ 'settings.functions.graphManager.parkingCharging.settings' | translate }}
        <div class="parky-chargy-settings-container" content>
          <app-parky-chargy-configuration
            [parkingAndChargingSettings]="viewModel.graphManagerParkingAndChargingSettings"
            [isLoaded]="graphManagerParkingAndChargingSettingsLoaded">
          </app-parky-chargy-configuration>
        </div>
      </ds-accordion-item>
    </ds-accordion>
  </ds-box-content>
</ds-box>

<ds-box class="mb-4x" data-cy="card">
  <ds-box-content>
    <app-toggle
      data-cy="enableVehicleSmoothing"
      translationKey="settings.functions.vehicleSmoothing"
      description="settings.functions.vehicleSmoothing.Description"
      tooltipText="{{
        ('settings.roles.permissions.Tooltip_Pretext' | translate) +
          ('settings.roles.permissions.ToggleVehicleSmoothing' | translate) +
          ('settings.roles.permissions.Tooltip_Posttext' | translate)
      }}"
      [toggle]="viewModel.vehicleSmoothingSettings"
      [isLoaded]="mapManagerSettingsLoaded"
      [requiredPermission]="'ToggleVehicleSmoothing'"
      (saveToggle)="onChangeVehicleSmoothingSettings($event)"></app-toggle>
  </ds-box-content>
</ds-box>

<ds-box class="mb-4x" data-cy="card">
  <ds-box-content>
    <div class="title">
      <app-labeled-input
        label="{{
          'settings.functions.missionErrorHandlingDefaults.title' | translate
        }}"></app-labeled-input>
      <div
        class="ms-2x"
        *ngIf="jobManagerSettingsLoaded; else error"
        [class.icon-disabled]="!hasPermission">
        <span data-cy="EditDefaultErrorHandling" (click)="onEditMissionErrorHandlingDefaults()"
          ><i
            class="pi pi-pencil"
            ds-tooltip="{{
              ('settings.roles.permissions.Tooltip_Pretext' | translate) +
                ('settings.roles.permissions.ToggleMissionAssignment' | translate) +
                ('settings.roles.permissions.Tooltip_Posttext' | translate)
            }}"
            [dsTooltipConfig]="{ disabled: hasPermission }"></i
        ></span>
      </div>
      <ng-template #error>
        <div class="error-background">
          <label data-cy="errorMessage" class="label-value error-text"
            ><ds-icon class="ms-2x icon" icon="error" tone="critical"></ds-icon>
            {{ 'settings.functions.unknownValue' | translate }}
          </label>
        </div>
      </ng-template>

      <div class="last-changed-date">
        <span> <i class="pi pi-clock"></i>{{ 'settings.functions.lastChange' | translate }}</span>
        <span data-cy="date">
          {{
            viewModel.missionErrorHandlingDefaults.dateUpdated
              ? (viewModel.missionErrorHandlingDefaults.dateUpdated.toString() | formatDateNow)
              : ('settings.functions.neverUpdated' | translate)
          }}
        </span>
      </div>
    </div>
    {{ 'settings.functions.missionErrorHandlingDefaults.description' | translate }}

    <div class="ms-1x mt-2x">
      <div class="title">
        <app-labeled-input
          label="{{
            'settings.featureToggles.jobAssignmentSection.missionErrorHandlingDefaultMainOption'
              | translate
          }}"></app-labeled-input>
      </div>
      <div data-cy="defaultErrorHandlingOptionValue">
        {{
          viewModel.missionErrorHandlingDefaults.defaultErrorHandlingOption || 0
            | enumTranslation : 'errorHandlingOptions'
            | notApplicable
        }}
      </div>

      <div class="title mt-4x">
        <app-labeled-input
          label="{{
            'settings.featureToggles.jobAssignmentSection.defaultValuesForCancelAbort' | translate
          }}"></app-labeled-input>
      </div>
      <div
        *ngIf="viewModel.missionErrorHandlingDefaults.releaseInterlock"
        data-cy="releaseInterlockValue">
        {{ 'shared.missionErrorHandlingModal.confirmInterlock' | translate }}
      </div>
      <div
        *ngIf="viewModel.missionErrorHandlingDefaults.confirmAbortToSap"
        data-cy="confirmAbortToSapValue">
        {{
          viewModel.missionErrorHandlingDefaults.confirmAbortToSapAs || 0
            | enumTranslation : 'missionTraceSapConfirmationOptions'
            | notApplicable
        }}
      </div>
    </div>
  </ds-box-content>
</ds-box>

<app-mission-error-handling-settings-modal
  [isModalOpen]="isModalOpen"
  [missionErrorHandlingDefaultsData]="viewModel.missionErrorHandlingDefaults"
  (confirm)="onConfirm($event)"
  (dismissModal)="dismissModal()">
</app-mission-error-handling-settings-modal>
