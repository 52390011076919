import { FeatureToggle } from 'core/dtos';
import { DateString, GuidString } from 'core/models';

export interface TrafficManagerFeatures {
  avoidConflictsAtPoisToggle: AvoidConflictsAtPoisDto;
  trafficManagementToggle: TrafficManagementSettingsDto;
}

export interface TrafficManagementSettings {
  settings: TrafficManagementSettingsDto;
  evacuationModeUpdatedUtc: DateString | null;
  avoidConflictsAtPoisUpdatedUtc: DateString | null;
  trafficManagementEnabledUpdatedTimeUtc: DateString | null;
}

export interface TrafficManagementSettingsMessage {
  trafficManagementSettings: TrafficManagementSettingsDto;
  workAreaId: GuidString;
}

export type AvoidConflictsAtPoisDto = FeatureToggle;

export interface TrafficManagementSettingsDto extends FeatureToggle {
  isEnabled?: boolean;
  dateUpdated: DateString | null;
  minimumStoppingAreaLengthMeters?: number;
  backwardMovementMinNegativeVelocityMetersPerSecond?: number;
  vehicleTurningDetectionAngleRad?: number;
  vehicleStateConsiderationTtlSeconds?: number;
  convoyMaxConflictAngleRad?: number;
  convoyMaxDistanceToPrioritizedVehicleMeters?: number;
  staleCollisionDistanceMeters?: number;
  staleDeadlockCollisionDistanceMeters?: number;
  maximumDistanceToIntersectionZoneMeters?: number;
  preferredDirectionMaxAngleRad?: number;
  antiBlockingMovingDetectionMinDistanceMeters?: number;
  antiBlockingMovingDetectionMinOrientationRad?: number;
  antiBlockingMaxStationaryVehicleSeconds?: number;
  antiBlockingHeadsOnMaxOrientationRad?: number;
  antiBlockingHeadsOnMaxDistanceMeters?: number;
  antiBlockingStaleDistanceToZoneRuleSeconds?: number;
  isAntiBlockingHeadsOnEnabled?: boolean;
  isAntiBlockingWaitingForEventEnabled?: boolean;
  isAntiBlockingInsideZoneEnabled?: boolean;
  isAntiBlockingDisconnectEnabled?: boolean;
  isAntiBlockingErrorStateEnabled?: boolean;
  isAntiBlockingStationaryEnabled?: boolean;
  isAntiBlockingStaleDistanceToZoneRuleEnabled?: boolean;
  isDirectedZoneRuleWithLookaheadAreaEnabled?: boolean;
  considerUnitLoad?: boolean;
  considerTuggerTrain?: boolean;
  considerForklift?: boolean;
  considerUagv?: boolean;
  considerIdleUnitLoad?: boolean;
  considerIdleTuggerTrain?: boolean;
  considerIdleForklift?: boolean;
  considerIdleUagv?: boolean;
  considerUnitLoadInManualMode?: boolean;
  considerTuggerTrainInManualMode?: boolean;
  considerForkliftInManualMode?: boolean;
  considerUagvInManualMode?: boolean;
  considerManualUnitLoadInZoneManagement?: boolean;
  considerManualTuggerTrainInZoneManagement?: boolean;
  considerManualForkliftInZoneManagement?: boolean;
  considerManualUagvInZoneManagement?: boolean;
  considerUnitLoadInError?: boolean;
  considerTuggerTrainInError?: boolean;
  considerForkliftInError?: boolean;
  considerUagvInError?: boolean;
  ignoreCommonUnitLoadCollision?: boolean;
  ignoreCommonTuggerTrainCollision?: boolean;
  ignoreCommonForkliftCollision?: boolean;
  ignoreCommonUagvCollision?: boolean;
  unitLoadLookAheadAreaWidthMeters?: number;
  tuggerTrainLookAheadAreaWidthMeters?: number;
  forkliftLookAheadAreaWidthMeters?: number;
  uagvLookAheadAreaWidthMeters?: number;
  unitLoadLookAheadAreaMinimumLengthMeters?: number;
  tuggerTrainLookAheadAreaMinimumLengthMeters?: number;
  forkliftLookAheadAreaMinimumLengthMeters?: number;
  uagvLookAheadAreaMinimumLengthMeters?: number;
  unitLoadLookAheadAreaSpeedMultiplier?: number;
  tuggerTrainLookAheadAreaSpeedMultiplier?: number;
  forkliftLookAheadAreaSpeedMultiplier?: number;
  uagvLookAheadAreaSpeedMultiplier?: number;
  unitLoadLoadedExtraWidthMeters?: number;
  tuggerTrainLookAheadAreaLoadedExtraWidthMeters?: number;
  forkliftLoadedExtraWidthMeters?: number;
  uagvLoadedExtraWidthMeters?: number;
  unitLoadRearWidthMeters?: number;
  tuggerTrainRearWidthMeters?: number;
  forkliftRearWidthMeters?: number;
  uagvRearWidthMeters?: number;
  unitLoadStoppingAreaWidthMeters?: number;
  tuggerTrainStoppingAreaWidthMeters?: number;
  forkliftStoppingAreaWidthMeters?: number;
  uagvStoppingAreaWidthMeters?: number;
  unitLoadRearLengthMeters?: number;
  tuggerTrainRearLengthMeters?: number;
  forkliftRearLengthMeters?: number;
  uagvRearLengthMeters?: number;
  unitLoadFrontLengthMeters?: number;
  tuggerTrainFrontLengthMeters?: number;
  forkliftFrontLengthMeters?: number;
  uagvFrontLengthMeters?: number;
  unitLoadDeadlockAreaLengthMeters?: number;
  tuggerTrainDeadlockAreaLengthMeters?: number;
  forkliftDeadlockAreaLengthMeters?: number;
  uagvDeadlockAreaLengthMeters?: number;
  unitLoadDeadlockAreaWidthMeters?: number;
  tuggerTrainDeadlockAreaWidthMeters?: number;
  forkliftDeadlockAreaWidthMeters?: number;
  uagvDeadlockAreaWidthMeters?: number;
  unitLoadDeadlockAreaLoadedWidthMeters?: number;
  tuggerTrainDeadlockAreaLoadedWidthMeters?: number;
  forkliftDeadlockAreaLoadedWidthMeters?: number;
  uagvDeadlockAreaLoadedWidthMeters?: number;
  unitLoadFootprintBufferMeters?: number;
  tuggerTrainFootprintBufferMeters?: number;
  forkliftFootprintBufferMeters?: number;
  uagvFootprintBufferMeters?: number;
}

export function getDefaultTrafficManagerFeatures(): TrafficManagerFeatures {
  return {
    trafficManagementToggle: { isToggledOn: false, dateUpdated: null },
    avoidConflictsAtPoisToggle: { isToggledOn: false, dateUpdated: null },
  };
}
