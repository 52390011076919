import { VehicleOptionStatus } from 'core/models';

export const ManageMapDataTones = {
  [VehicleOptionStatus.AlreadyEnabledMap]: 'positive',
  [VehicleOptionStatus.Available]: 'positive',
  [VehicleOptionStatus.Busy]: 'caution',
  [VehicleOptionStatus.Offline]: 'caution',
  [VehicleOptionStatus.MapNotAvailableOnVehicle]: 'info',
  [VehicleOptionStatus.AlreadySentMap]: 'positive',
};

export const ManageMapDataColors = {
  [VehicleOptionStatus.AlreadyEnabledMap]: '--ds-color-green-100',
  [VehicleOptionStatus.Available]: '',
  [VehicleOptionStatus.Busy]: '--ds-color-yellow-100',
  [VehicleOptionStatus.Offline]: '--ds-color-yellow-100',
  [VehicleOptionStatus.MapNotAvailableOnVehicle]: '--ds-color-blue-100',
  [VehicleOptionStatus.AlreadySentMap]: '--ds-color-green-100',
};
