import { createSelector, MemoizedSelector } from '@ngrx/store';
import { BasicVehicleDimensions, Factsheet } from 'core/dtos';
import { GuidString } from 'core/models';

import { CM_PER_METER } from 'core/constants';
import * as fromFactsheetsReducer from '../reducers/factsheet.reducer';
import * as fromVehicleFeatureState from '../reducers/index';

const selectFactsheetState = createSelector(
  fromVehicleFeatureState.getVehiclesFeatureState,
  fromVehicleFeatureState.getFactsheetState
);

export const selectFactsheetEntities = createSelector(
  selectFactsheetState,
  fromFactsheetsReducer.getEntities
);

export const selectFactsheetsLoading = createSelector(
  selectFactsheetState,
  fromFactsheetsReducer.getLoading
);

export const selectFactsheetsLoaded = createSelector(
  selectFactsheetState,
  fromFactsheetsReducer.getLoaded
);

export const selectAllFactsheets = createSelector(
  selectFactsheetState,
  fromFactsheetsReducer.getAllFactsheets
);

export const selectFactsheetByVehicleId = (
  vehicleId: GuidString
): MemoizedSelector<fromVehicleFeatureState.VehiclesFeatureState, Factsheet | undefined> =>
  createSelector(selectAllFactsheets, factsheets => {
    return factsheets.find(fs => fs.vehicleId === vehicleId);
  });

// #region Vehicle Dimensions
export interface FactsheetDimensions extends BasicVehicleDimensions {
  vehicleId: GuidString;
}

export const selectFactsheetDimensions = createSelector(selectAllFactsheets, factsheets => {
  return factsheets.map(fs => calculateDimensions(fs));
});

export const calculateDimensions = (factsheet: Factsheet): FactsheetDimensions | undefined => {
  if (!factsheet.agvGeometry.envelopes2D || factsheet.agvGeometry.envelopes2D.length === 0)
    return undefined;

  const points = factsheet.agvGeometry.envelopes2D[0].polygonPoints;
  const xAxis = points.map(p => p.x);
  const yAxis = points.map(p => p.y);

  const xMin = Math.min(...xAxis);
  const yMin = Math.min(...yAxis);
  const xMax = Math.max(...xAxis);
  const yMax = Math.max(...yAxis);

  const length = (xMax - xMin) * CM_PER_METER;
  const width = (yMax - yMin) * CM_PER_METER;
  const origin = Math.abs(xMin) * CM_PER_METER;

  return {
    vehicleId: factsheet.vehicleId,
    length,
    width,
    origin,
  };
};

// #endregion
