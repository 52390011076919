import { PoiType, PoiVector, PoiVectorOrientation } from 'core/models';

export const DEFAULT_VECTOR_DISTANCE = 2.6;

export function getDefaultVector(poiType: PoiType | undefined): PoiVector {
  switch (poiType) {
    case PoiType.DollyPlace:
    case PoiType.ChargingStation:
    case PoiType.ParkingSpace:
    case PoiType.TripleTurnTable:
    case PoiType.TurnTableLarge:
    case PoiType.TurnTableSmall:
    case PoiType.ParkingSpaceLarge:
    case PoiType.RackChangerDropoff:
    case PoiType.PrePositionPoi:
      return {
        entry: { distance: DEFAULT_VECTOR_DISTANCE, orientation: PoiVectorOrientation.Backwards },
        exit: { distance: DEFAULT_VECTOR_DISTANCE, orientation: PoiVectorOrientation.Backwards },
      };
    case PoiType.RackChangerPickup:
      return {
        entry: { distance: 1.1, orientation: PoiVectorOrientation.Backwards },
        exit: { distance: 1.1, orientation: PoiVectorOrientation.Backwards },
      };
    case PoiType.Handover:
      return {
        entry: { distance: 2.0, orientation: PoiVectorOrientation.Backwards },
        exit: { distance: 1.1, orientation: PoiVectorOrientation.Backwards },
      };
    case PoiType.PalletStationCrosswise:
    case PoiType.PalletStationLengthwise:
      return {
        entry: { distance: DEFAULT_VECTOR_DISTANCE, orientation: PoiVectorOrientation.Backwards },
        exit: { distance: DEFAULT_VECTOR_DISTANCE, orientation: PoiVectorOrientation.Forwards },
      };
    case PoiType.WayPoint:
    case PoiType.AutomaticConveyorLoading:
    case PoiType.AssignableWaypoint:
    default:
      return {
        entry: { distance: 0, orientation: PoiVectorOrientation.Backwards },
        exit: { distance: 0, orientation: PoiVectorOrientation.Backwards },
      };
  }
}
