/* eslint-disable max-lines */
import { EMPTY_GUID } from 'core/constants';
import {
  ActionState,
  defaultActionState,
  defaultMetrics,
  DsOrderState,
  InterlockDetail,
  MessageMetrics,
  MissionListSignalRDto,
  PackedActionState,
  PackedDsOrderState,
  PackedInterlockDetails,
  PackedMessageMetrics,
  PackedMissionFailureReason,
  PackedTourStepTrace,
  ReducedMissionFailureReason,
  StepDto,
  TourStepDto,
  unpackEventTraceDto,
} from 'core/dtos';
import {
  convertReducedMissionFailureToFailureComment,
  convertStepDtoToStepModel,
  getFrontEndStepType,
} from 'core/helpers';
import { convertTourStepToTourStepModel } from 'core/helpers/tour-step-dto-to-model.helper';
import {
  DateString,
  MissionPriorityLevel,
  MissionStatus,
  MissionTrace,
  Pos2D,
  Pose2D,
  StepModel,
  StepTraceModel,
  StepTypeBackend,
} from 'core/models';

export function CSharpTicksToTimestamp(ticks: string): number {
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  return Number((BigInt(ticks) - BigInt('621355968000000000')) / BigInt(1e4));
}

export function packedToPositionWithTheta(t: [number, number, number]): Pos2D {
  return { x: t[0], y: t[1], theta: t[2] };
}

export function packedToPositionWithOrientation(t: [number, number, number]): Pose2D {
  return {
    x: t[0],
    y: t[1],
    orientation: t[2],
  };
}

export function unpackMessageMetrics(data: PackedMessageMetrics): MessageMetrics {
  if (!data) {
    return defaultMetrics;
  }

  const [ih, ig, fm, sr, tm] = data;

  return {
    iotHubEnqueuedTimeUtc: ih.toISOString(),
    ingestEnqueuedTimeUtc: ig.toISOString() || '',
    fleetManagerEnqueuedTimeUtc: fm.toISOString(),
    signalREnqueuedTimeUtc: sr.toISOString(),
    trafficManagerEnqueuedTimeUtc: tm?.toISOString() || null,
  };
}

function unpackActionStates(data: PackedActionState): ActionState {
  if (!data) {
    return defaultActionState;
  }

  const [ai, at, ad, as, rd] = data;

  return {
    actionId: ai ?? '',
    actionType: at ?? '',
    actionDescription: ad ?? '',
    actionStatus: as,
    resultDescription: rd ?? '',
  };
}

export function unpackOrderState(data: PackedDsOrderState | null): DsOrderState | null {
  if (!data) {
    return null;
  }

  return {
    orderId: data[0],
    actionStates: data[1].map(as => unpackActionStates(as)),
  };
}

export function unpackMissionFailureReason(
  data: PackedMissionFailureReason | null,
  vehicleName: string | null,
  missionName: string
): ReducedMissionFailureReason | null {
  if (!data) {
    return null;
  }

  return {
    missionFailureReasonId: data[0],
    missionFailureReasonComments: data[1],
    missionFailureMinutesToSolve: data[2],
    missionFailureMinutesForEmergencyProcess: data[3],
    errorClass: data[4] || undefined,
    missionFailureLocationId: data[5],
    durationOfMissionInFailed: data[6],
    missionFailureStartDateTime: data[7],
    missionFailureEndDateTime: data[8],
    missionFailureShiftGroupId: data[9],
    vehicleName,
    missionName,
  };
}

export function unpackMissionStepTraces(mission: MissionListSignalRDto): StepTraceModel[] {
  const result: StepTraceModel[] = [];

  for (const backendStep of mission.steps) {
    const sourceStep = extractSourceStepFromJson(backendStep[0], mission);

    if (sourceStep != null) {
      result.push({
        sourceStep: sourceStep,
        startedDateTime: unpackToNullableDateString(backendStep[1]),
        stoppedDateTime: unpackToNullableDateString(backendStep[2]),
        succeeded: backendStep[3],
        attributeText: '',
      });
    }
  }
  let seqNum = 0;
  for (const step of result) {
    step.sourceStep.sequenceNumber = seqNum++;
  }

  return result;
}

export function getSequenceNumberConsideringEvents(missionTrace: MissionListSignalRDto): number {
  if (missionTrace.curStepNr === -1) {
    return -1;
  }
  let index = -1;
  const sequenceMapping = {};

  if (missionTrace.steps) {
    for (const stepTrace of missionTrace.steps) {
      const sourceStep = extractSourceStepFromJson(stepTrace[0], missionTrace);
      if (sourceStep != null) {
        sequenceMapping[sourceStep.sequenceNumber] = ++index;
      }
    }
  }
  return sequenceMapping[missionTrace.curStepNr] ?? missionTrace.curStepNr;
}

export function unpackInterlockDetailDto(data: PackedInterlockDetails[]): InterlockDetail[] {
  return data?.map(
    (et): InterlockDetail => ({
      eventId: et[0],
      raisingMissionId: et[1] ?? EMPTY_GUID,
      stepSequenceNumber: et[2],
      frontendStepSequenceNumber: et[3],
      stepType: et[4],
      stepAttribute: et[5],
      raisingMissionName: et[6],
    })
  );
}

function extractSourceStepFromJson(
  sourceStep: string,
  mission: MissionListSignalRDto
): StepModel | null {
  const plannedStep: StepDto = JSON.parse(sourceStep);

  if (plannedStep.type === StepTypeBackend.RaiseEvent) return null;

  const interlockDetails = unpackInterlockDetailDto(mission.interlocks);
  const eventTraces = unpackEventTraceDto(mission.events);

  return convertStepDtoToStepModel(plannedStep, eventTraces, interlockDetails);
}

export function unpackMissionListFromSignalR(missionList: MissionListSignalRDto[]): MissionTrace[] {
  return missionList.map(m => {
    const failureUnpacked = unpackMissionFailureReason(m.failure, m.vhc, m.mission);
    const stepTracesUnpacked = unpackMissionStepTraces(m);
    const tourStepsUnpacked = m.tours ? unpackTourStepTraces(m.tours) : [];

    return {
      id: m.id,
      mapId: m.mapId,
      missionId: m.mId,
      missionName: m.mission,
      processChainName: m.pcName,
      processChainTraceId: m.pctId,
      processChainId: m.pcId,
      materialNumber: m.mat,
      status: m.status,
      fleetId: m.fId,
      vehicleName: m.vhc,
      vehicleId: m.vId,
      vehicleType: m.vhcType,
      isVehiclePaused: m.vhcPause,
      trigger: m.trigger,
      currentStepSequenceNumber: getSequenceNumberConsideringEvents(m),
      currentStepType: m.curStpType,
      stepDetails: m.stepText,
      currentStepAttributeText: m.stepText || '',
      createdDateTime: m.created.toISOString(),
      provisioningTime: m.provisioning?.toISOString() || null,
      forecastedEndTime:
        m.status === MissionStatus.InProgress && m.forecasted ? m.forecasted.toISOString() : null,
      completedDateTime: m.complete?.toISOString() || null,
      updatedDateTime: m.updated.toISOString(),
      lateDeliveryAlarmTime: m.deliverTime || 0,
      deliveryStatus: m.deliverStatus,
      hasManualSupport: m.hasSupport,
      assignableWaypointName: m.wayPt,
      canAbort: m.abort,
      canRetry: m.retry,
      errorHandlingAllowed: m.errorHandle,
      missionDisplayHighlightLevel: m.highlight,
      missionFormat: m.fmt,
      tourSteps: tourStepsUnpacked.map(tour => convertTourStepToTourStepModel(tour)),
      priorityLevel: m.priority || MissionPriorityLevel.None,
      assignmentDelayEndDateTime: m.delayEnd?.toISOString() || '',
      missionFailureReasonId: failureUnpacked?.missionFailureReasonId,
      missionFailureReasonComments: failureUnpacked?.missionFailureReasonComments,
      missionFailureMinutesToSolve: failureUnpacked?.missionFailureMinutesToSolve,
      missionFailureReasonEn: '',
      missionFailureReasonDe: '',
      missionFailureReasonDate: failureUnpacked?.missionFailureStartDateTime?.toString(),
      missionFailureErrorClass: failureUnpacked?.errorClass,
      missionFailureMinutesForEmergencyProcess:
        failureUnpacked?.missionFailureMinutesForEmergencyProcess,
      missionFailureLocationId: failureUnpacked?.missionFailureLocationId,
      missionFailureShiftGroupId: failureUnpacked?.missionFailureShiftGroupId,
      durationOfMissionInFailed: failureUnpacked?.durationOfMissionInFailed,
      missionFailureStartDateTime: failureUnpacked?.missionFailureStartDateTime?.toString(),
      missionFailureEndDateTime: failureUnpacked?.missionFailureEndDateTime?.toString(),
      missionStepNo: `${m.curStepNr}/${m.stpCnt}`,
      assignableWaypointId: m.wayPtId || null,
      assignmentConditions: m.aCond,
      maxExecutionTime: m.maxExec || 0,
      isAlarmForLateDeliveryActive: m.isAlarmLateDel,
      canBeContinuedFromStep: m.continue,
      canBeReAssigned: m.reassign,
      isAssignedToVehicle: m.isVhcAssign,
      stepCount: m.stpCnt,
      failureComment: convertReducedMissionFailureToFailureComment(failureUnpacked),
      isMaxExecutionTimeActive: m.isMaxExecTimeActive,
      stepTraces: stepTracesUnpacked,
      currentStepTypeFrontEnd: getFrontEndStepType(m.curStpType, m.curStepNr, stepTracesUnpacked),
      loadType: m.loadType,
      tourChainId: m.tourChainId,
      tourChainName: m.tourChainName,
      scanValue: m.scanValue,
      fifoOrderTime: m.fifoTime?.toISOString(),
    };
  });
}

function unpackTourStepTraces(tours: PackedTourStepTrace[]): TourStepDto[] {
  return tours.map(tourTrace => {
    const startedDateUtc = tourTrace[3] && unpackToDateString(tourTrace[3]);
    const stoppedDateUtc = tourTrace[4] && unpackToDateString(tourTrace[4]);
    return {
      id: tourTrace[0],
      type: tourTrace[1],
      stepAttributeText: tourTrace[2] || undefined,
      startedDateUtc,
      stoppedDateUtc,
      sequenceId: tourTrace[5],
      skipped: tourTrace[6],
      alreadyExecuted: tourTrace[7],
    };
  });
}

function unpackToDateString(date: Date): DateString {
  return date.toISOString();
}

function unpackToNullableDateString(date: Date | null): DateString | null {
  return !date ? null : new Date(date).toISOString();
}
