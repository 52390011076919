/* eslint-disable @typescript-eslint/no-magic-numbers */
/* eslint-disable max-lines */
import '@pixi/graphics-extras';
import { CM_PER_METER } from 'core/constants';
import { PoiDto } from 'core/dtos';
import {
  GuidString,
  MapItemType,
  PoiDeviceOccupancy,
  PoiType,
  PoisFilter,
  Pose2D,
  poiTypesWithOccupancy,
} from 'core/models';
import { MapTransparency as MapAlpha } from 'modules/maps/constants';
import { MapPixiHelper } from 'modules/maps/helpers';
import { MapLayerView } from 'modules/maps/models';
import { Graphics, Point, Sprite, Texture } from 'pixi.js';
import { GeometryConverter, GeometryHelper } from 'shared/helpers';
import { LayerGraphicHelper } from '../helpers/map-layer-graphic.helper';
import { MapItemBase, RotateMapItem } from '../map-item-container';
import { MapItemRotationContainer, RotationStyle } from '../map-item-rotation-container.graphic';
import { POILayerImages } from '../map-layer-images.constant';
import { ItemLabelStyle, MapDragTarget, MapIconAnchor, MapScale } from '../map-layer.constant';
import { MapLabelGraphic } from '../shared';
import { PoiGraphicHelper } from './helpers/poi-graphi.helper';
import { PoiDimensions } from './poi-dimension.model';
import { PoiBases, PoiIcon } from './poi-layer-sprites.constant';
import { IconStyle, PoiSelection, SensorIconStyle, VectorStyle } from './poi.constant';

export abstract class PoiMapItem implements MapItemBase, RotateMapItem {
  container!: MapItemRotationContainer;
  protected dimensions!: PoiDimensions;

  protected background!: Sprite;
  protected icon: Sprite | undefined;
  protected label: MapLabelGraphic | undefined;
  protected deviceOccupancyIcon: Sprite | undefined;

  private entryVector: Graphics | undefined;
  private exitVector: Graphics | undefined;

  private selection: Graphics | undefined;

  protected isSelected = false;
  private mapOrientation = 0;
  private xLabelOffset = 0;

  get id(): GuidString {
    return this.poi?.id;
  }

  get position(): Pose2D {
    return this.container.itemPosition;
  }

  constructor(
    protected readonly view: MapLayerView,
    protected poi: PoiDto,
    protected filter?: PoisFilter
  ) {
    this.create(poi);
  }

  // #region Create
  protected abstract setDimensions(type: PoiType): PoiDimensions;
  protected abstract getRotationStyle(type: PoiType): RotationStyle;

  protected create(poi: PoiDto): void {
    this.dimensions = this.setDimensions(poi.type);

    this.container = this.view.addChild(
      new MapItemRotationContainer(
        poi.id.toString(),
        MapItemType.Poi,
        this.getRotationStyle(poi.type)
      )
    );
    this.container.scale.set(MapScale.MeterToCm, -MapScale.MeterToCm);
    this.container.interactive = true;
    this.container.buttonMode = true;
    this.container.position.copyFrom(poi.mainPose);

    this.createPoiContainer(poi);
    this.setOrientation(poi.mainPose.orientation);
    this.setVisibility(this.filter);
  }

  private createPoiContainer(poi: PoiDto): void {
    this.createTypeContainer(poi);

    this.createVectors(poi);
    this.createDeviceOccupancyIcon(poi);
    this.createLabel(poi);
  }

  private createTypeContainer(poi: PoiDto): void {
    this.container.addChild(
      (this.background = this.createPoiBackground(poi.type, this.dimensions))
    );

    if (PoiIcon[poi.type]) {
      this.container.addChild((this.icon = this.createIconByType(poi.type, this.dimensions)));
    }
  }

  protected createPoiBackground(poiType: PoiType, dimensions: PoiDimensions): Sprite {
    const base = PoiBases[poiType] ?? POILayerImages.POI_Box;

    const sprite = Sprite.from(base, IconStyle);
    sprite.width = dimensions.length;
    sprite.height = dimensions.width;
    sprite.anchor.set(dimensions.origin / dimensions.length, 0.5);

    return sprite;
  }

  private createIconByType(poiType: PoiType, dimensions: PoiDimensions): Sprite {
    const url = PoiIcon[poiType]; // Add default broken icon

    if (!url) return new Sprite();
    const texture = Texture.from(url, IconStyle);
    const icon = PoiGraphicHelper.createIcon(texture, IconStyle);

    LayerGraphicHelper.positionToItemCenter(icon, dimensions);

    return icon;
  }

  // #endregion

  // #region Update
  update(poi: PoiDto): void {
    if (this.poi.type !== poi.type) {
      this.updateType(poi);
    }

    if (this.poi.name !== poi.name && this.label) {
      this.label.updateLabelText(poi.name);
    }

    if (
      this.poi.exitVectorDistance !== poi.exitVectorDistance ||
      this.poi.entryVectorDistance !== poi.entryVectorDistance
    ) {
      this.updateVectors(poi);
    }

    this.setPosition(poi.mainPose);
    this.setOrientation(poi.mainPose.orientation);
    this.poi = poi;
  }

  updateStatus(poi: PoiDto): void {
    const shouldShowDevice = this.checkDeviceIcon(poi);

    if (shouldShowDevice) {
      if (this.deviceOccupancyIcon) {
        if (!this.deviceOccupancyIcon.visible || this.poi.deviceOccupancy !== poi.deviceOccupancy) {
          this.deviceOccupancyIcon.destroy();
          this.createDeviceOccupancyIcon(poi);
        }
      } else {
        this.createDeviceOccupancyIcon(poi);
      }
    } else if (this.deviceOccupancyIcon) {
      this.deviceOccupancyIcon.visible = false;
    }

    this.poi = poi;
  }

  private updateType(poi: PoiDto): void {
    this.container.removeChild(this.background);
    this.background.destroy();

    if (this.icon) {
      this.container.removeChild(this.icon);
      this.icon.destroy();
      this.icon = undefined;
    }

    if (this.label) {
      this.container.removeChild(this.label);
      this.label.destroy();
      this.label = undefined;
    }

    this.dimensions = this.setDimensions(poi.type);
    this.container.setRotationStyle(this.getRotationStyle(poi.type));

    this.createTypeContainer(poi);
    this.updateVectors(poi);
    this.createDeviceOccupancyIcon(poi);
    this.createLabel(poi);

    if (this.selection) {
      this.container.removeChild(this.selection);
      this.selection?.destroy();

      this.createSelection(poi.type);
    }

    this.container.clearRotation();
    this.container.showRotation(poi.mainPose.orientation);
  }

  setPosition(point: Point | Pose2D): void {
    if (point.x && point.y) {
      this.container.position.copyFrom(point);
    }
  }

  remove(): void {
    this.container.removeChildren();
    this.container.parent.removeChild(this.container);

    this.container.destroy();
  }
  // #endregion

  // #region Device
  private getSensorIcon(poi: PoiDto): string {
    return poi.deviceOccupancy === PoiDeviceOccupancy.Loaded
      ? POILayerImages.POI_Sensor_Occupied
      : POILayerImages.POI_Sensor_Free;
  }

  private checkDeviceIcon(poi: PoiDto): boolean {
    return !(
      !poiTypesWithOccupancy.includes(poi.type) ||
      !poi.occupancyDevice ||
      !poi.deviceOccupancy
    );
  }

  private createDeviceOccupancyIcon(poi: PoiDto): void {
    if (!this.checkDeviceIcon(poi)) return;

    const deviceIcon = Sprite.from(this.getSensorIcon(poi));
    deviceIcon.name = MapDragTarget.Rotation;
    deviceIcon.scale.set(1 / SensorIconStyle.Scale);
    deviceIcon.anchor.set(MapIconAnchor.Middle);
    deviceIcon.position.set(SensorIconStyle.OffsetX, SensorIconStyle.OffsetY);
    deviceIcon.visible = true;

    this.deviceOccupancyIcon = deviceIcon;

    if (this.icon) this.icon.addChild(deviceIcon);
  }
  // #endregion

  // #region Label
  private createLabel(poi: PoiDto): void {
    const label = LayerGraphicHelper.createLabel(poi.name);

    this.xLabelOffset = this.dimensions.length / 2 - this.dimensions.origin;
    this.container.addChildOnTop(label);

    label.pivot.set(0, this.calculateLabelOffset(poi.mainPose.orientation, label.height));

    label.position.x = this.xLabelOffset;
    this.label = label;
  }

  protected calculateLabelOffset(orientation: number, xPositionOffset: number): number {
    const labelHeight = this.label && !this.label.destroyed ? this.label.height : 0;

    return (
      GeometryHelper.calculateOffsetFromOrigin(
        orientation,
        this.dimensions.width,
        this.dimensions.length,
        this.dimensions.origin + xPositionOffset
      ) +
      labelHeight +
      ItemLabelStyle.Padding
    );
  }

  changeName(name: string): void {
    if (!this.label || !name) return;

    this.label.updateLabelText(name);
  }
  // #endregion

  // #region Vectors
  private createVectors(poi: PoiDto): void {
    const yOffset = !!(poi.entryVectorDistance && (poi.exitVectorDistance ?? 0) < 0);

    this.createEntryVector(poi.entryVectorDistance ?? 0, yOffset);
    this.createExitVector(poi.exitVectorDistance ?? 0, yOffset);

    this.showVectors(this.isSelected);
  }

  private createEntryVector(distance: number, yOffset: boolean): void {
    if (!distance) return;

    this.entryVector = this.createVectorLine(
      VectorStyle.EntryColor,
      distance,
      yOffset ? -VectorStyle.Size : 0
    );

    this.container.addChildAtBottom(this.entryVector);
  }

  private createExitVector(distance: number, yOffset: boolean): void {
    if (!distance) return;

    this.exitVector = this.createVectorLine(
      VectorStyle.ExitColor,
      distance,
      yOffset ? VectorStyle.Size : 0
    );

    this.container.addChildAtBottom(this.exitVector);
  }

  private createVectorLine(color: number, distance: number, yOffset: number): Graphics {
    const distanceCm = distance * CM_PER_METER;

    return PoiGraphicHelper.createVectorLine(
      color,
      { x: 0, y: yOffset },
      { x: distanceCm, y: yOffset }
    );
  }

  private updateVectors(poi: PoiDto): void {
    if (this.entryVector) {
      this.container.removeChild(this.entryVector);
      this.entryVector.destroy();
      this.entryVector = undefined;
    }

    if (this.exitVector) {
      this.container.removeChild(this.exitVector);
      this.exitVector.destroy();
      this.exitVector = undefined;
    }

    this.createVectors(poi);
  }
  // #endregion

  // #region Selection
  toggleSelection(isSelected: boolean): void {
    if (isSelected) {
      this.createSelection(this.poi.type);
      this.view.setChildOnTop(this.container);
    } else {
      this.selection?.destroy();
      this.selection = undefined;
    }

    this.isSelected = isSelected;
    this.showVectors(isSelected);
  }

  protected createSelection(type: PoiType): void {
    const graphic = this.getSelectionGraphic(type);
    graphic.visible = true;

    this.selection = graphic;
    this.container.addChildAtBottom(graphic);
  }

  protected getSelectionGraphic(type: PoiType): Graphics {
    if (type === PoiType.WayPoint) {
      return MapPixiHelper.createSelection({
        width: PoiSelection.Border,
        color: PoiSelection.Color,
        size: this.dimensions.width / 2,
      });
    } else {
      return LayerGraphicHelper.createBaseFromDimensions(this.dimensions, {
        alpha: MapAlpha.Transparent,
        color: PoiSelection.Color,
        borderSize: PoiSelection.Border,
        borderColor: PoiSelection.Color,
        corner: PoiSelection.Radius,
      });
    }
  }

  // #endregion

  // #region Rotation

  toggleRotationMode(enable: boolean): void {
    if (enable) {
      this.container.showRotation(this.poi.mainPose.orientation ?? 0);
      this.setOrientation(this.poi.mainPose.orientation);
    } else this.container.clearRotation();
  }

  private setLabelOrientation(orientation: number): void {
    if (this.label) {
      const relativeOrientation = this.mapOrientation + orientation;
      this.label.pivot.set(0, this.calculateLabelOffset(relativeOrientation, this.xLabelOffset));
      this.label.rotation = relativeOrientation;
    }
  }

  setMoveRotation(radian: number): void {
    this.setOrientation(GeometryConverter.radianToPositiveValue(radian));
  }

  setOrientation(orientation: number): void {
    this.container.rotation = orientation;
    if (this.icon) this.icon.rotation = orientation + this.mapOrientation;

    this.setLabelOrientation(orientation);

    this.container.setItemRotation(orientation);
  }

  onMapRotation(mapRotation: number): void {
    this.mapOrientation = mapRotation;
    this.container.setMapRotation(mapRotation);
    this.setOrientation(this.poi.mainPose.orientation);

    if (this.icon) this.icon.rotation = this.poi.mainPose.orientation + mapRotation;

    this.setLabelOrientation(this.poi.mainPose.orientation);
  }

  // #endregion

  // #region Filter
  setVisibility(filter?: PoisFilter): void {
    if (!filter) return;

    this.filter = filter;
    this.container.visible =
      (!!filter.occupancy.find(
        x =>
          this.poi.occupancy?.occupancyStatus === undefined ||
          x.id === this.poi.occupancy.occupancyStatus
      )?.selected &&
        !!filter.type.find(x => x.id === this.poi.type)?.selected) ||
      this.isSelected;

    if (this.label) this.label.visible = filter.name;

    this.showVectors(this.isSelected);
  }

  private showVectors(isSelected: boolean): void {
    const showVectors =
      (!!this.poi.entryVectorDistance || !!this.poi.exitVectorDistance) &&
      (isSelected || !!this.filter?.entryExitVector);

    if (this.entryVector) this.entryVector.visible = showVectors;
    if (this.exitVector) this.exitVector.visible = showVectors;
  }
  // #endregion
}
