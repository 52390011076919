/* eslint-disable max-lines */
import { SelectListOption } from '@bmw-ds/components';
import { EMPTY_GUID } from 'core/constants';
import {
  BaseVehicleMapDataDto,
  CollisionPoint,
  Coordinate,
  IntersectionCollisionPoint,
  MapDto,
  MapImage,
  MapNavigationDto,
  MassCommandMapDataResponseDto,
  MassVdaMapRequestDto,
  PathEdgeDto,
  PillarsGridDto,
  PreviewDeleteMapDto,
  VdaMapUploadDataRequest,
  VehicleConflictAreaDimensions,
  VehicleMap,
  VehicleMapDataDownloadRequest,
  VehicleMapDataDto,
  VehicleMapDataListItemModel,
} from 'core/dtos';
import {
  CollisionCase,
  EntityList,
  GuidString,
  HardwareVersion,
  IntersectionCollisionType,
  MapItem,
  MapItemType,
  MapSearchItem,
  MapsFilter,
  MapStatus,
  ReducedMap,
  ReleaseType,
  SelectedMapItem,
  VehicleFilter,
  VehicleFilterType,
  VehicleGroup,
  VehicleOptionStatus,
  VehicleType,
  VehicleTypeFilter,
  VehicleTypeItem,
} from 'core/models';

import { lookAheadAreaPathEdges } from './map-vehicle-mock-data';

export const map1Id = 'bb2b55bc-dd94-4262-a5af-966431d57b31';

const map2Id = '03840cd1-a25a-4c33-a7ba-1fc05e3f0212';
const map3Id = '03840cd2-a27a-4c83-a9ba-2fc05e3f0300';
const map4Id = '26e35f79-8e1a-40fa-8ee1-002c5e6b858e';
export const map5Id = '26e35f79-8e1a-40fa-8ee1-002c5e6b855s';
const navigationLayer1id = 'acbc3d54-0e7a-43b0-865c-daeaf4a8971a';

const vehicleIds = ['025a9bc8-9a21-464f-b807-a1caa12a596a', 'd3977f76-10f2-4f72-aa61-b2107a7a49e5'];

const pillarsGrid: PillarsGridDto = {
  id: '6539e39a-afbf-4993-53af-08d85a2d530e',
  mapId: map2Id,
  startPose2D: {
    x: 0,
    y: 0,
  },
  distanceBetweenColumnsInMeters: 2.0,
  distanceBetweenRowsInMeters: 2.0,
  rows: ['A', 'B', 'C', 'D'],
  columns: ['1', '2', '3', '4'],
};

const previewDeleteMapDTO: PreviewDeleteMapDto = {
  id: map1Id,
  name: 'Map 1',
  creationDateTime: '2022-07-22T14:39:24',
  pois: 2,
  zoneSets: 3,
  vehicles: 4,
  fleets: 3,
  processChains: 5,
  nodes: 6,
  edges: 7,
  routes: 8,
  tourConfigurations: 9,
  psaMappings: 10,
  nodeGroups: 11,
};

const maps: MapDto[] = [
  {
    name: 'map 1',
    id: map1Id,
    creationDateTime: '2022-07-22T14:39:26',
    origin: {
      x: 0,
      y: 0,
      z: 1,
    },
    backgroundTransparency: 50.0,
    mapBackgroundImageUrl: '',
    workAreaId: '',
  },
  {
    name: 'map 2',
    id: map2Id,
    creationDateTime: '2022-07-11T05:27',
    origin: {
      x: 0,
      y: 0,
      z: 1,
    },
    backgroundTransparency: 0,
    mapBackgroundImageUrl: null,
    workAreaId: '',
  },
  {
    name: 'map without zone-set',
    id: map3Id,
    creationDateTime: '2021-04-06T05:15:18',
    origin: {
      x: 0,
      y: 0,
      z: 1,
    },
    backgroundTransparency: 0,
    mapBackgroundImageUrl: null,
    workAreaId: '',
  },
  {
    name: 'map with zero offset',
    id: map4Id,
    creationDateTime: '2020-09-14T12:08:10',
    origin: {
      x: 0,
      y: 0,
      z: 1,
    },
    backgroundTransparency: 0,
    mapBackgroundImageUrl: null,
    workAreaId: '',
  },
  {
    name: 'map with display order index out of bounds',
    id: map5Id,
    creationDateTime: '2020-09-14T12:08:10',
    origin: {
      x: 0,
      y: 0,
      z: 1,
    },
    backgroundTransparency: 0,
    mapBackgroundImageUrl: null,
    workAreaId: '',
  },
];

const uploadVehicleMapData = {
  navigationLayerId: navigationLayer1id,
  name: 'Name',
};

const vehicleMapData = {
  id: 'e9704898-002e-4209-aff5-827c202bf113',
  name: 'vehicleMapDataName',
  fileName: 'fileName',
  blobUrl: 'blobUrl',
  navigationLayerId: navigationLayer1id,
  vehicleId: 'dfcbe822-39b7-4f65-b87f-831397f87b123',
  createdOnUtc: '2022-11-25T08:23:17.5517524Z',
  mapVersion: '2022-11-26T08:23:17.5517524Z',
};

const vehicleMapDataListItem = {
  id: 'e9704898-002e-4209-aff5-827c202bf113',
  name: 'vehicleMapDataName',
  fileName: 'fileName',
  blobUrl: 'blobUrl',
  mapName: 'mapName',
  navigationLayerName: 'navigationLayerName',
  vehicleId: 'dfcbe822-39b7-4f65-b87f-831397f87b123',
  vehicleName: 'STR_001',
  createdOnUtc: '2022-11-25T08:23:17.5517524Z',
  hasDeletedData: false,
  vehicleGroup: VehicleGroup.Str,
  navigationLayerId: '3f943d08-21d2-44c7-8b30-d219721a4315',
  mapId: 'b4ba23b5-27f7-4036-a1e1-cafbe5f04b51',
  mapVersion: '2022-11-26T08:23:17.5517524Z',
};

const vehicleOption: SelectListOption = {
  id: 'vehicle1',
  label: 'Vehicle 1',
  vehicleOptionStatus: VehicleOptionStatus.AlreadyEnabledMap,
  disabled: false,
  value: {},
};

const collisionPoint: CollisionPoint = {
  id: 'collision-id',
  mapId: 'map-1',
  prioritizedVehicleId: 'vehicle-2-test',
  pausedVehicleId: 'vehicle-1-test',
  pose2D: { x: 10, y: 10, orientation: 0 },
  collisionCase: CollisionCase.Undefined,
  releaseType: ReleaseType.NotReleased,
  isDeadlockCollision: false,
};

const intersectionCollisionPoint: IntersectionCollisionPoint = {
  id: 'collision-id',
  mapId: 'map-1',
  prioritizedAccessVehicleId: 'vehicle-2-test',
  blockedAccessVehicleId: 'vehicle-1-test',
  pose2D: { x: 10, y: 10, orientation: 0 },
  collisionDateTimeUtc: '',
  type: IntersectionCollisionType.InnerZoneWithGrantedVehicle,
  isDeadlockHandled: false,
  masterZoneId: 'zone-id',
};

const vehicleConflictAreaDimensions: VehicleConflictAreaDimensions = {
  workAreaId: 'xxx',
  vehicleId: 'vehicleId',
  lookAheadArea: lookAheadAreaPathEdges,
  stoppingArea: lookAheadAreaPathEdges,
  deadlockArea: lookAheadAreaPathEdges,
};

const background: MapImage = {
  transparency: 50,
  imageStreamBase64: 'url',
};

const vehicleTypeFilter: VehicleFilterType = { name: true, path: true, pathSegments: true };

const filterVehicleAll: VehicleFilter = {
  type: [
    { name: true, path: true, pathSegments: true },
    { name: true, path: true, pathSegments: true },
    { name: true, path: true, pathSegments: true },
    { name: true, path: true, pathSegments: true },
    { name: true, path: true, pathSegments: true },
  ],
  mode: [
    { id: 0, selected: true },
    { id: 1, selected: true },
  ],
  status: [
    { id: 0, selected: true },
    { id: 1, selected: true },
    { id: 2, selected: true },
    { id: 3, selected: true },
    { id: 4, selected: true },
    { id: 5, selected: true },
    { id: 6, selected: true },
    { id: 7, selected: true },
  ],
  load: [
    { id: 0, selected: true },
    { id: 1, selected: true },
  ],
  maintenance: [
    { id: 0, selected: true },
    { id: 1, selected: true },
  ],
  manual: [{ id: 1, selected: true }],
  search: '',
};

const typeFilter: VehicleTypeFilter = {
  key: true,
  filter: [
    { name: false, path: true, pathSegments: false },
    { name: true, path: false, pathSegments: true },
    { name: false, path: false, pathSegments: true },
    { name: true, path: true, pathSegments: true },
    { name: false, path: false, pathSegments: false },
  ],
};

const filter: VehicleFilter = {
  type: [
    typeFilter.filter[0],
    typeFilter.filter[1],
    typeFilter.filter[2],
    typeFilter.filter[3],
    typeFilter.filter[4],
  ],
  mode: [
    { id: 0, selected: false },
    { id: 1, selected: false },
  ],
  status: [
    { id: 0, selected: false },
    { id: 1, selected: false },
    { id: 2, selected: false },
    { id: 3, selected: false },
    { id: 4, selected: false },
    { id: 5, selected: false },
    { id: 6, selected: false },
    { id: 7, selected: false },
  ],
  load: [
    { id: 0, selected: false },
    { id: 1, selected: false },
  ],
  maintenance: [
    { id: 0, selected: false },
    { id: 1, selected: false },
  ],
  manual: [{ id: 1, selected: false }],
  search: '',
};

const filterVehicleEmpty: VehicleFilter = {
  type: {
    0: {
      path: false,
      name: false,
      pathSegments: false,
    },
    1: {
      path: false,
      name: false,
      pathSegments: false,
    },
    2: {
      path: false,
      name: false,
      pathSegments: false,
    },
    4: {
      path: false,
      name: false,
      pathSegments: false,
    },
  },
  mode: [],
  status: [],
  load: [],
  maintenance: [],
  manual: [],
  search: '',
};

const updatedItems: VehicleTypeItem[] = [
  {
    id: VehicleType.UnitLoad,
    key: 'type.0',
    control: 'vehicleType.0',
    selected: false,
    children: [
      {
        translationKey: '',
        property: 'name',
        selected: typeFilter.filter[0].name,
      },
      {
        translationKey: '',
        property: 'path',
        selected: typeFilter.filter[0].path,
      },
      {
        translationKey: '',
        property: 'pathSegments',
        selected: typeFilter.filter[0].pathSegments,
      },
    ],
  },
  {
    id: VehicleType.TuggerTrain,
    key: 'type.1',
    control: 'vehicleType.1',
    selected: false,
    children: [
      {
        translationKey: '',
        property: 'name',
        selected: typeFilter.filter[1].name,
      },
      {
        translationKey: '',
        property: 'path',
        selected: typeFilter.filter[1].path,
      },
      {
        translationKey: '',
        property: 'pathSegments',
        selected: typeFilter.filter[1].pathSegments,
      },
    ],
  },
];

export class MapMockData {
  // #region Maps
  static getMapList(): EntityList[] {
    return maps.map(x => ({
      id: x.id,
      name: x.name,
    }));
  }

  static getMap(): MapDto {
    return { ...maps[0] };
  }

  static getPreviewDeleteMapDTO(): PreviewDeleteMapDto {
    return previewDeleteMapDTO;
  }

  static getMap2(): MapDto {
    return { ...maps[1] };
  }

  static getMaps(): MapDto[] {
    return [...new Set([maps[0], maps[1]])];
  }

  static getMapWithoutZoneSet(): MapDto {
    return { ...maps[2] };
  }

  static getMapFromId(mapId: GuidString): MapDto | undefined {
    return maps.find(m => m.id === mapId);
  }

  static getReducedMap(): ReducedMap {
    return {
      id: maps[0].id,
      name: maps[0].name,
      workAreaId: maps[0].workAreaId,
      resolution: 1,
      backgroundImage: {
        height: 10,
        width: 12,
        transparency: 100,
        imageUrl: 'imageUrl',
      },
    };
  }

  static getEmptySelectedMapItem(type = MapItemType.Poi): SelectedMapItem<MapItem> {
    const point: Coordinate = {
      x: 0,
      y: 0,
    };

    const item: MapItem = {
      id: EMPTY_GUID.toString(),
      position: point,
      type,
    };

    return {
      item: item,
      isFocused: false,
    };
  }

  static getSearchItems(): MapSearchItem[] {
    return [
      {
        id: 'aa03c3c1-9123-4fe4-b0d1-3cb97ce2c405',
        pose2D: {
          x: 98,
          y: 56,
          orientation: 89,
        },
        name: 'Node name 2',
      },
      {
        id: '4d5cfb49-1a1c-432f-9a0e-ae38364eba1c',
        pose2D: {
          x: 45,
          y: 55,
          orientation: 22,
        },
        name: 'Node name 1',
      },
    ];
  }

  static mapVehicleMapNavigation(mapId: GuidString): MapNavigationDto {
    return {
      id: mapId,
      navigationLayerId: '',
    };
  }
  // #endregion

  // #region Vehicle & Related data

  static getVehicleMapDataRequest(): BaseVehicleMapDataDto {
    return uploadVehicleMapData;
  }

  static getVehicleMapData(): VehicleMapDataDto[] {
    return [vehicleMapData];
  }

  static getVehicleIds(): GuidString[] {
    return vehicleIds;
  }

  static getVehicleMap(): VehicleMap {
    return {
      mapId: 'mapId1',
      mapVersion: 'Map Version 1',
      mapStatus: MapStatus.DISABLED,
      mapDescription: '',
    };
  }

  static getVehicleMapDataListItems(): VehicleMapDataListItemModel[] {
    return [vehicleMapDataListItem];
  }

  static getVehicleMapDataDownloadRequest(): VehicleMapDataDownloadRequest {
    return {
      navigationLayerId: '04b7511f-3cb0-49ec-aa6a-ebe83397d3c2',
      vehicleMapDataId: 'd5e474bf-5e4b-426b-b11c-fd59610d2005',
      vehicleIds: ['6f697237-94e8-4b77-9530-2671780450e1', '9222cd12-8610-4ea5-a151-daf7beae88f2'],
      mapId: '7013cac6-3386-480e-9dc0-fa5e97dc7907',
    };
  }

  static getVehicleMapDataDownloadResponse(): MassCommandMapDataResponseDto {
    return {
      failedVehicleIds: ['6f697237-94e8-4b77-9530-2671780450e1'],
      successfullySent: 1,
    };
  }

  static getVehicleMassDataRequest(): MassVdaMapRequestDto {
    return {
      navigationLayerId: EMPTY_GUID,
      vehicleIds: [],
      mapId: EMPTY_GUID,
      mapVersion: '',
    };
  }

  static getAreaPathEdges(): PathEdgeDto[] {
    return lookAheadAreaPathEdges;
  }
  // #endregion

  static getDsMapUploadDataRequest(): VdaMapUploadDataRequest {
    return {
      mapDataFile: new File([''], 'mapData', { type: 'text/json' }),
      mapVersion: 'map version',
      navigationLayerId: 'acbc3d54-0e7a-43b0-865c-daeaf4a8971a',
      name: 'mapdata name',
      hardwareVersion: HardwareVersion.TuggerTrainDsV1,
      vehicleGroup: VehicleGroup.TuggerTrainDs,
    };
  }
  // #endregion

  // #region Collision Points
  static getCollisionPoint(): CollisionPoint {
    return { ...collisionPoint };
  }

  static getCollisionPointList(): CollisionPoint[] {
    return [
      { ...collisionPoint, id: 'collision-1-id' },
      { ...collisionPoint, id: 'collision-2-id' },
    ];
  }

  static getIntersectionCollisionPoint(): IntersectionCollisionPoint {
    return { ...intersectionCollisionPoint };
  }

  static getIntersectionCollisionPointList(): IntersectionCollisionPoint[] {
    return [
      { ...intersectionCollisionPoint, id: 'is-coll-1-id' },
      { ...intersectionCollisionPoint, id: 'is-coll-2-id' },
    ];
  }

  static getVehicleConflictAreaDimensions(): VehicleConflictAreaDimensions {
    return { ...vehicleConflictAreaDimensions };
  }

  static getVehicleOptions(): SelectListOption[] {
    return [
      {
        ...vehicleOption,
        id: 'vehicle1',
        label: 'Vehicle 1',
        vehicleOptionStatus: VehicleOptionStatus.AlreadyEnabledMap,
        disabled: false,
        value: {},
      },
      {
        ...vehicleOption,
        id: 'vehicle2',
        label: 'Vehicle 2',
        vehicleOptionStatus: VehicleOptionStatus.AlreadyEnabledMap,
        disabled: false,
        value: {},
      },
      {
        ...vehicleOption,
        id: 'vehicle3',
        label: 'Vehicle 3',
        vehicleOptionStatus: VehicleOptionStatus.AlreadyEnabledMap,
        disabled: false,
        value: {},
      },
    ];
  }

  // #endregion
  static getPillarsGrid(): PillarsGridDto {
    return { ...pillarsGrid };
  }

  static getMapBackgroundImage(): MapImage {
    return { ...background };
  }

  // #region Filter
  static getMapVehicleFilterAllChecked(): VehicleFilter {
    return { ...filterVehicleAll };
  }

  static getVehicleFilter(): VehicleFilter {
    return filter;
  }

  static getVehicleTypeFilter(): VehicleTypeFilter {
    return typeFilter;
  }

  static getVehicleFilterUpdatedItems(): VehicleTypeItem[] {
    return updatedItems;
  }

  static getVehicleTypeFilterItem(): VehicleFilterType {
    return vehicleTypeFilter;
  }

  static getMapVehicleFilterEmpty(): VehicleFilter {
    return { ...filterVehicleEmpty };
  }

  static getMapFilterEmpty(): MapsFilter {
    return {
      backgroundMap: false,
      baseMap: false,
      pillarsGrid: false,
      pathPlannerMap: false,
      graphMap: false,
    };
  }

  static getMapFilterAll(): MapsFilter {
    return {
      backgroundMap: true,
      baseMap: true,
      pillarsGrid: true,
      pathPlannerMap: true,
      graphMap: true,
    };
  }
  // #endregion

  // #region Files

  static getMockLifFile(): File {
    return new File([''], 'lif', { type: 'text/json' });
  }

  static getMockPngFile(): File {
    return new File([''], 'mapImage', { type: 'image/png' });
  }
  // #endregion
}
